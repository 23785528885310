
import {
  defineComponent,
  reactive,
  ref,
  toRefs,
  nextTick,
  onMounted,
  onBeforeUnmount,
  getCurrentInstance,
  ComponentInternalInstance
} from 'vue'
import ZUpload from '@/components/upload/ZUpload.vue'
import ZUploadImage from '@/components/upload/ZUploadImage.vue'
import { resume } from './config'
import { ElMessage, ElMessageBox } from 'element-plus'
import { Switch, Plus, Minus, CirclePlusFilled, RemoveFilled, WarningFilled } from '@element-plus/icons-vue'
import { util } from '@/utils/util.js'
import { useRouter, useRoute, onBeforeRouteUpdate } from 'vue-router'
import { useStore } from 'vuex'
import type { ElForm } from 'element-plus'
import GetCity from '@/components/getCity/pc.vue'

interface stateOption {
  isFreeLogin: boolean
  encryptStr: string | null
  id: number | null
  postId: any
  fileList: any
  form: any
  resume: any
  formOps: any
  selectOps: any
  typeList: any
  photoUrl: string
  empPhoto: string
  resumeType: number | null
  position: any
  submitFormType: number
  recruitTypeVisible: boolean
  isFixed: boolean
  hightLine: any
  tabList: any
  hasMaxEducationType: boolean
  pushAreaDisplay: boolean
  familyConfig: any[]
  pageNum: number
  loading: boolean
  positionOptions: any
  busy: boolean
  queryString: string
  schoolVolunteers: any
  originSchoolVolunteers: any
  isHas: boolean
  tipsText: any[]
}
type FormInstance = InstanceType<typeof ElForm>

export default defineComponent({
  components: {
    ZUpload,
    ZUploadImage,
    Switch,
    GetCity,
    Plus,
    Minus,
    CirclePlusFilled,
    RemoveFilled,
    WarningFilled
  },
  setup() {
    const { proxy, appContext }: { proxy: any; appContext: any } = getCurrentInstance() as ComponentInternalInstance
    const $dayjs = appContext.config.globalProperties.$dayjs
    const router = useRouter()
    const route = useRoute()
    const store = useStore()

    const state = reactive({
      isFreeLogin: false,
      encryptStr: null,
      id: null,
      postId: null,
      recruitTypeVisible: false,
      fileList: [],
      resumeType: null, // 1-社招 2-校招
      submitFormType: 0,
      isFixed: false,
      loading: false,
      positionOptions: [],
      wishPosition: {},
      busy: false,
      pageNum: 1,
      queryString: '',
      schoolVolunteers: [],
      originSchoolVolunteers: [],
      isHas: false, //校招填志愿: 社招一线是否有过投递 且正在流程中
      form: {
        realName: '',
        // 教育经历
        educationExperiences: [],
        // 工作经历(社招)
        workExperiences: [],
        // 项目经历
        projectExperiences: [],
        // 实习经历(校招)
        practiceExperienceVoList: [],
        // 在校职务(校招)
        schoolOfficeVoList: [],
        // 作品 worksType: 1-作品附件 2-作品链接
        personalWorksList: [],
        // 获奖
        awardsWonsVoList: [],
        // 语言
        languageAbilityVoList: [],
        // 自我评价
        selfEvaluation: null,
        educationExperiencesIsEmpty: '1',
        workIsEmpty: '1',
        //内推码
        pushCode: null,
        recommendedType: null,
        familyMemberVoList: [],
        // 接受其他岗位调剂
        isJobTransfer: false
        // proIsEmpty: '1'
      },
      selectOps: {
        SEX: [
          {
            dataName: '男',
            dataCode: '0'
          },
          {
            dataName: '女',
            dataCode: '1'
          }
        ]
      },
      photoUrl: '',
      empPhoto: '',
      position: {
        postNameOut: null,
        deptNameStr: null,
        requireAgeName: null,
        workPlaceNameStr: null
      },
      resume,
      formOps: {},
      typeList: [],
      hightLine: 0,
      tabList: [],
      // 教育经历有最高学历
      hasMaxEducationType: false,
      pushAreaDisplay: false,
      tipsText: [],
      familyConfig: [
        { label: '亲属姓名', value: 'familyName', isRequire: true },
        {
          label: '亲属性别',
          value: 'sex',
          key: 'sexKey',
          selectKey: 'SEX',
          formType: 'select',
          selectEnum: [],
          isRequire: true
        },
        { label: '亲属联系方式', value: 'familyPhone', width: '100px', span: 7, isRequire: true }
      ]
    }) as stateOption

    // formOps重构
    state.formOps = util.formatForm(resume)

    onMounted(() => {
      if (!location.href.includes('encryptStr=')) {
        // 处理微信登录重定向: url带code和state不挂载
        if (!localStorage.getItem('Authorization')) {
          store.dispatch('setRedirectUrl', route.fullPath)
          router.replace({
            name: 'Login'
          })
          return false
        }
        if (!(location.href.includes('code=') && location.href.includes('state='))) {
          state.postId = route.query.postId
          judgeIshasPostId()
        }
      } else {
        state.isFreeLogin = true
        state.encryptStr = location.href.split('encryptStr=')[1]
        getSelectOptions()
        getResumeInfoFree()
        window.addEventListener('scroll', initScroll, true)
      }
      getTipsText()
    })

    onBeforeRouteUpdate((to) => {
      if (!(location.href.includes('code=') && location.href.includes('state='))) {
        state.postId = to.query.postId
        judgeIshasPostId()
      }
    })

    /**
     * @description : 判断是[职位投递页]还是[我的简历]
     * @result : postId： 有-判断投递状态 无：获取简历信息
     */
    const judgeIshasPostId = () => {
      if (state.postId) {
        judgeResumeStatu()
      } else {
        getSelectOptions()
        getResumeInfo()
      }
      window.addEventListener('scroll', initScroll, true)
    }

    // 检查手机号输入 回写第一志愿
    const checkMessage = () => {
      state.schoolVolunteers = []
      proxy.$http.position.checkDelivery({ postId: state.postId }).then((res) => {
        if (res) {
          state.form.phone = res.phone
          if (res.name) state.form.name = res.name
          if (res.email) state.form.email = res.email
          state.isHas = Boolean(res.isHas)
          state.originSchoolVolunteers = res.schoolVolunteers ? res.schoolVolunteers : []
          if (res.schoolVolunteers && res.schoolVolunteers.length) {
            res.schoolVolunteers[0].isDefault = true
            state.schoolVolunteers.push(res.schoolVolunteers[0])
          }
          console.log('iiiiiiiiiiiiiiii', state.schoolVolunteers)
        }
        state.schoolVolunteers.push({
          postId: Number(state.postId),
          postName: state.position.postNameOut,
          twoWorkPlaceName: state.position.twoWorkPlaceName,
          controlCenterName: state.position.controlCenterName,
          newOther: true
        })
      })
    }

    const getTipsText = () => {
      proxy.$http.base
        .getByGroup({
          groupName: 'wechat_delivery_remind'
        })
        .then((res) => {
          console.log('res', res)
          if (res && res.length) {
            const tips = res[0].value
            state.tipsText = tips.split('\n')
            console.log('state', state.tipsText)
          }
        })
    }

    //
    const addVolunteers = () => {
      state.schoolVolunteers.push({
        postId: null,
        postName: null,
        twoWorkPlaceName: null,
        controlCenterName: null,
        newOther: true
      })
    }
    const deleteVolunteer = () => {
      state.schoolVolunteers.splice(1, 1)
    }
    const changeVolunteer = (item, val) => {
      // el-option 的disabled属性失效了  所以在这里单独加下限制
      if (state.schoolVolunteers.find((i) => i.postId == val.id && i.postId != item.postId)) {
        item.postId = null
        item.postName = null
        item.twoWorkPlaceName = null
        item.controlCenterName = null
      } else {
        item.postId = val.id
        item.postName = val.postNameOut
        item.twoWorkPlaceName = val.twoWorkPlaceName
        item.controlCenterName = val.controlCenterName
      }
    }

    const changeVoluntSort = () => {
      const obj = state.schoolVolunteers.splice(1, 1)[0]
      state.schoolVolunteers.unshift(obj)
    }

    /**
     * @description : [职位投递页]校验简历投递状态
     * @result : 可以投递-获取岗位信息获取简历信息
     * @result : 不可投递-超过职位投递个数的最大限制2-跳转回前一页
     * @result : 不可投递-已投递此岗位，请勿重复投递！跳转回前一页
     * @result : 不可投递-未登录-跳转到登录页
     */
    const judgeResumeStatu = () => {
      proxy.$http.resume
        .checkResumeStatu({ postId: state.postId, recruitingSourceKey: '31' })
        .then(() => {
          getPositionDetail()
          getSelectOptions()
          getResumeInfo()
        })
        .catch((err: any) => {
          if (err.statusCode == '403') {
            store.dispatch('setRedirectUrl', route.fullPath)
            router.replace({
              name: 'Login'
            })
          } else {
            router.go(-1)
          }
        })
    }
    // 数字转换方法
    const arabicToChineseNumber = (number) => {
      const chineseNumberMap = {
        0: '零',
        1: '一',
        2: '二',
        3: '三',
        4: '四',
        5: '五',
        6: '六',
        7: '七',
        8: '八',
        9: '九'
      }

      const numStr = String(number)
      const chineseNumber = []

      for (let i = 0; i < numStr.length; i++) {
        const digit = Number(numStr[i])
        const digitChinese = chineseNumberMap[digit]
        chineseNumber.push(digitChinese)
      }

      return chineseNumber.join('')
    }
    onBeforeUnmount(() => {
      window.removeEventListener('scroll', initScroll, true)
    })

    const initScroll = () => {
      const offsetHeight = proxy.$refs['wish'] ? proxy.$refs['wish'].offsetHeight + 90 : 0
      console.log('ssssssssssss', offsetHeight)

      let offsetTop = 48 + offsetHeight
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      let screenHeight = window.screen.height
      state.isFixed = scrollTop > offsetTop ? true : false
      let scrollIds = document.getElementsByClassName('r-title') as any
      for (let i = 0; i < scrollIds.length; i++) {
        if (scrollTop + 200 >= scrollIds[i].offsetTop) {
          state.hightLine = i
        }
      }
    }

    /**
     * @description : 获取岗位详情
     * @result : 职位不在发布中-返回
     * @result : 职位在发布中-获取招聘类型
     */
    const getPositionDetail = () => {
      proxy.$http.position.getPostInfoDetail({ id: state.postId }).then((res: any) => {
        if (res.status == 2) {
          state.position = res
          if (res.channelId == 3) {
            state.resumeType = 1
          } else {
            state.resumeType = res.channelId || null
          }
          handleResumeTypeInit()
        } else {
          router.push({
            name: 'PositionDetail',
            query: {
              id: state.postId
            }
          })
        }
      })
    }

    /**
     * @description : 获取Select下拉基础数据
     * @description : resume前调用
     * @result : 最高学历 最高学位 学习形式 熟练程度
     */
    const getSelectOptions = () => {
      proxy.$http.base.queryKvList({ group: 'C_EDU_XUELI' }).then((res: any) => {
        state.selectOps[`C_EDU_XUELI`] = res
      })
      proxy.$http.base.queryKvList({ group: 'C_EDU_TYPE' }).then((res: any) => {
        state.selectOps[`C_EDU_TYPE`] = res
      })
      proxy.$http.base.queryKvList({ group: 'C_EDU_XUEWEI' }).then((res: any) => {
        state.selectOps[`C_EDU_XUEWEI`] = res
      })
      proxy.$http.base.queryKvList({ group: 'C_HR_LANG_DEGREE' }).then((res: any) => {
        state.selectOps[`C_HR_LANG_DEGREE`] = res
      })
      proxy.$http.base.queryKvList({ group: 'MAR_STATUS' }).then((res: any) => {
        state.selectOps[`MAR_STATUS`] = res
      })
      proxy.$http.base.queryKvList({ group: 'C_EDU_XUEWEI' }).then((res: any) => {
        state.selectOps[`C_EDU_XUEWEI`] = res
      })
      proxy.$http.base.queryKvList({ group: 'C_EDU_XL_FLAG' }).then((res: any) => {
        state.selectOps[`C_EDU_XL_FLAG`] = res
      })
      proxy.$http.base.queryKvList({ group: 'C_LANGUAGE' }).then((res: any) => {
        state.selectOps[`C_LANGUAGE`] = res
      })
      proxy.$http.base.queryKvList({ group: 'C_HR_LANG_LEVEL' }).then((res: any) => {
        state.selectOps[`C_HR_LANG_LEVEL`] = res
      })
      proxy.$http.base.queryKvList({ group: 'C_NID_STATE' }).then((res: any) => {
        state.selectOps[`C_NID_STATE`] = res
      })
      proxy.$http.base.getDictionaryCascade({ groupName: 'year_limit' }).then((res: any) => {
        state.selectOps[`year_limit`] = res.map((v) => {
          return {
            dataCode: v.key,
            dataName: v.value
          }
        })
      })
    }

    /**
     * @description : 获取简历信息！！！
     * @description : 注意异常处理
     */
    const getResumeInfo = () => {
      proxy.$http.resume.getResumeInfo().then((res: any) => {
        // 处理手机号 姓名 邮箱信息  及社招志愿信息
        if (state.postId) checkMessage()

        state.form = res
        if (store.state.userInfo.mobile) {
          state.form.phone = store.state.userInfo.mobile
        }
        state.id = res.id
        state.photoUrl = res.photoUrl
        state.empPhoto = res.empPhoto
        if (state.form.recommendedType) {
          state.pushAreaDisplay = true
        }
        if (!state.postId) {
          if (!state.id) {
            state.recruitTypeVisible = true
          } else {
            // 招聘类型：没有招聘类型时才改 默认取社招
            if (state.resumeType === null) {
              state.resumeType = res.resumeType || 1
            }
            // 不展示一线简历
            if (state.resumeType === 3) {
              state.recruitTypeVisible = true
              state.resumeType = 1
            }
            // 处理社招校招展示
            handleResumeTypeInit()
          }
        }
        handleResumeInfo(res)
      })
    }

    /**
     * 处理未登录简历
     *
     */
    const getResumeInfoFree = () => {
      proxy.$http.blue.getResumeInfo({ encryptStr: state.encryptStr }).then((res: any) => {
        state.form = res.baseResumeInfo
        state.id = state.form.id
        state.photoUrl = state.form.photoUrl
        state.empPhoto = res.empPhoto
        state.recruitTypeVisible = false
        state.resumeType = res.type
        // state.resumeType = 2
        state.postId = res.postId
        state.position = {
          postNameOut: res.postName,
          deptNameStr: res.deptName,
          requireAgeName: res.requireAgeName,
          workPlaceNameStr: res.workPlaceName
        }
        if (
          state.resumeType === 1 &&
          res.baseResumeInfo.familyMemberVoList &&
          res.baseResumeInfo.familyMemberVoList.length > 0
        ) {
          state.form.familyMemberVoList = res.baseResumeInfo.familyMemberVoList
          state.form.isTakeOffice = '1'
          state.form.familyMemberVoList.forEach((el) => {
            el.sex = el.sexKey === '0' ? '男' : '女'
          })
        }
        if (res.baseResumeInfo.isTakeOffice === 0) {
          state.form.isTakeOffice = '0'
        }
        if (!state.form.familyMemberVoList) {
          state.form.familyMemberVoList = []
        }
        handleResumeTypeInit()
        handleResumeInfo(res.baseResumeInfo)
      })
    }

    /**
     * 处理登录和未登录简历
     *
     */
    const handleResumeInfo = (res) => {
      for (const k of Object.keys(resume)) {
        if (k != 'base') {
          if (!state.form[k]) {
            state.form[k] = []
          }
        }
      }

      console.log('handleResumeInfo')

      // 处理作品
      console.log(res)
      if (res.personalWorksList) {
        state.form.personalWorksList = state.form.personalWorksList.map((item: any) => {
          return {
            worksType: item.worksType,
            worksDescription: item.worksDescription,
            fileList:
              item.worksType == 1
                ? [
                    {
                      name: item.attachmentSourceName,
                      url: item.attachmentUrl,
                      attachmentName: item.attachmentName,
                      attachmentSourceName: item.attachmentSourceName,
                      attachmentUrl: item.attachmentUrl,
                      gmtCreate: item.gmtCreate
                    }
                  ]
                : [],
            worksUrl: item.worksType == 2 ? item.worksUrl : null
          }
        })
      }

      // 处理dateRange
      state.typeList.forEach((elements: any) => {
        if (state.form[elements.key]) {
          state.form[elements.key].forEach((element: any) => {
            if (element.startTime && element.endTime) {
              element.dateRange = [
                element.startTime,
                element.endTime === '至今' ? $dayjs(Date.now()).format('YYYY-MM-DD') : element.endTime
              ]
            } else {
              element.dateRange = []
            }
          })
        }
      })

      // 手机号disabled处理 没有手机号不置灰

      if (!state.form.phone && localStorage.getItem('Authorization')) {
        resume.base.children.forEach((v) => {
          if (v.value == 'phone') {
            v.disabled = false
          }
        })
      }

      // 年龄处理
      if (state.form.birthday) {
        state.form.age = util.getAge(state.form.birthday.split('-'))
        resume.base.children.forEach((v) => {
          if (v.value == 'age') {
            v.disabled = true
          }
        })
      }

      // 教育经历 - 学历类型处理
      if (state.form.educationExperiences && state.form.educationExperiences.length) {
        state.hasMaxEducationType = state.form.educationExperiences.some((el) => el.educationTypeKey === '30')
      }

      if (state.form.isTakeOffice === 1) {
        state.form.isTakeOffice = '1'
      }
      if (state.form.isTakeOffice === 0) {
        state.form.isTakeOffice = '0'
      }

      //测试用
      // state.form.expectWorkCity = ''
      // state.form.expectWorkCityId = '310000>310100'
      if (state.form.expectWorkCityId && !state.form.expectWorkCity) {
        proxy.$http.base.getRegionInfoTreeList().then((res) => {
          console.log('getRegionInfoTreeList', res)
          const data = state.form.expectWorkCityId.split('>')
          let expectWorkCity = []
          const first = res.children.find((v) => {
            return v.code === data[0]
          })
          if (first) {
            expectWorkCity.push(first.name)
            const result = first.children.find((v) => {
              return v.code === data[1]
            })
            if (result) {
              expectWorkCity.push(result.name)
              state.form.expectWorkCity = expectWorkCity.join('/')
            }
          }
        })
      }
      nextTick(() => {
        proxy.$refs['formRef'] && proxy.$refs['formRef'].clearValidate()
      })
    }

    // 提交校验
    const validForm = (submitFormType: number) => {
      state.submitFormType = submitFormType
      validIsEmpty()
      proxy.$refs['formRef'].validate((valid: any) => {
        if (valid) {
          if (
            state.resumeType === 2 &&
            state.schoolVolunteers.find((v) => {
              return !v.postId
            })
          ) {
            console.log('不满足')
            ElMessage.warning('志愿不能为空')
            window.scrollTo({
              top: 0,
              behavior: 'smooth'
            })
            return
          }
          ElMessageBox.confirm(`${submitFormType == 1 ? '最多能投递2个岗位，确定投递' : '确定保存'}？`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            submitForm()
          })
        } else {
          scrollIntoView()
          return false
        }
      })
    }

    // 保存简历
    const submitForm = () => {
      state.typeList.forEach((elements: any) => {
        if (state.form[elements.key]) {
          state.form[elements.key].forEach((element: any) => {
            element.startTime = element.dateRange[0]
            element.endTime = element.dateRange[1]
          })
        }
      })
      // 个人作品处理
      const personalWorksList = state.form.personalWorksList.map((item: any) => {
        return {
          worksType: item.worksType,
          worksUrl: item.worksType == 2 ? item.worksUrl : null,
          attachmentName: item.worksType == 1 ? item.fileList[0].attachmentName : null,
          attachmentSourceName: item.worksType == 1 ? item.fileList[0].attachmentSourceName : null,
          attachmentUrl: item.worksType == 1 ? item.fileList[0].attachmentUrl : null,
          worksDescription: item.worksDescription
        }
      })
      if (!state.form.recommendedType || state.form.recommendedType === 1) {
        state.form.recommendedName = ''
        state.form.recommendedInfo = ''
      }
      if (!state.form.recommendedType || state.form.recommendedType === 2) {
        state.form.pushCode = ''
      }
      const params = {
        id: state.id,
        resumeType: state.resumeType,
        photoUrl: state.empPhoto ? null : state.photoUrl,
        empPhoto: state.empPhoto,
        realName: state.form.realName,
        sexKey: state.form.sexKey,
        phone: state.form.phone,
        email: state.form.email,
        birthday: state.form.birthday,
        // age: state.form.age,
        marryKey: state.form.marryKey,
        marryValue: state.form.marryValue,
        workYears: state.form.workYears,
        workYearsValue: state.form.workYearsValue,
        nativePlaceKey: state.form.nativePlaceKey,
        nativePlaceValue: state.form.nativePlaceValue,
        currentResidence: state.form.currentResidence,
        expectWorkCity: state.form.expectWorkCity,
        expectWorkCityId: state.form.expectWorkCityId,
        expectSalary: state.form.expectSalary,
        currSalary: state.form.currSalary,
        selfEvaluation: state.form.selfEvaluation,
        educationExperiences: state.form.educationExperiences,
        projectExperiences: state.form.projectExperiences,
        workExperiences: state.form.workExperiences,
        practiceExperiences: state.form.practiceExperienceVoList,
        schoolOfficeVoList: state.form.schoolOfficeVoList,
        personalWorksList: personalWorksList,
        awardsWonsList: state.form.awardsWonsVoList,
        languageAbilityList: state.form.languageAbilityVoList,
        pushCode: state.form.pushCode,
        encryptStr: state.encryptStr,
        recommendedName: state.form.recommendedName,
        recommendedInfo: state.form.recommendedInfo,
        recommendedType: state.form.recommendedType,
        familyMemberList: state.form.isTakeOffice === '1' ? state.form.familyMemberVoList : [],
        isTakeOffice: state.form.isTakeOffice,
        isJobTransfer: state.form.isJobTransfer
      }
      if (state.isFreeLogin == false) {
        proxy.$http.resume.updateResumeInfo(params).then((res: any) => {
          if (state.submitFormType == 1) {
            sendResume()
          } else {
            ElMessage.success(`保存成功！`)
            router.go(-1)
          }
        })
      } else {
        proxy.$http.blue.updateResumeInfo(params).then((res: any) => {
          ElMessage.success(`更新成功！`)
        })
      }
    }

    // 投递简历
    const sendResume = () => {
      let data = {
        postId: state.postId,
        recruitingSourceKey: '31',
        pushCode: state.form.pushCode,
        postOrderPoList: []
      }

      console.log('xxxxxxxxxxxxxxxxx', state.schoolVolunteers)

      if (state.resumeType == 2) {
        if (state.originSchoolVolunteers.length) {
          const hasPostIds = state.originSchoolVolunteers.map((v) => {
            return v.postId
          })
          console.log('school', state.schoolVolunteers, state.originSchoolVolunteers)
          const preparePostIds = state.schoolVolunteers
            .map((v) => {
              return v.postId
            })
            .filter((v) => {
              return !hasPostIds.includes(v)
            })

          const findIndex = state.schoolVolunteers.findIndex((v) => {
            return preparePostIds.includes(v.postId)
          })
          console.log('findIndex', hasPostIds, preparePostIds, findIndex, state.schoolVolunteers)
          data.postOrderPoList = [
            {
              postId: Number(preparePostIds.join('')),
              chooseOrder: findIndex + 1
            }
          ]
        } else {
          data.postOrderPoList = state.schoolVolunteers.map((v, index) => {
            return {
              postId: Number(v.postId),
              chooseOrder: Number(index + 1)
            }
          })
        }
      }
      proxy.$http.position.sendResume(data).then((res: any) => {
        ElMessage.success(`投递成功`)
        router.push({
          name: 'PositionDetail',
          query: {
            id: state.postId
          }
        })
      })
    }

    // 校验List是否为空
    const validIsEmpty = () => {
      state.form.educationExperiencesIsEmpty =
        state.form.educationExperiences && state.form.educationExperiences.length == 0 ? null : '1'
      state.form.projectExperiencesIsEmpty =
        state.form.projectExperiences && state.form.projectExperiences.length == 0 ? null : '1'
      if (state.resumeType == 1) {
        state.form.workExperiencesIsEmpty =
          state.form.workExperiences && state.form.workExperiences.length === 0 ? null : '1'
      }
    }

    // 增加一行
    const addRow = (value: string) => {
      console.log(`state.formOps`, state.formOps)
      if (value === 'push') {
        state.pushAreaDisplay = !state.pushAreaDisplay
        if (!state.form.recommendedType) {
          state.form.recommendedType = 1
        }
        return
        // state.
      }
      // 只能有一条最高学历
      // 已有最高学历 - 其他 不可编辑
      // 没有最高学历 - 可编辑
      if (value === 'educationExperiences') {
        state.hasMaxEducationType = state.form.educationExperiences.some((el) => el.educationTypeKey === '30')
        if (state.hasMaxEducationType) {
          state.formOps[value].educationTypeValue = '其他'
          state.formOps[value].educationTypeKey = '40'
        } else {
          state.formOps[value].educationTypeValue = null
          state.formOps[value].educationTypeKey = null
        }
      }
      state.form[value].push(JSON.parse(JSON.stringify(state.formOps[value])))

      validIsEmpty()
    }

    // 删除一行
    const removeRow = (value: string, index: number) => {
      if (value === 'push') {
        state.pushAreaDisplay = false
        state.form.recommendedType = ''
        state.form.recommendedName = ''
        state.form.recommendedInfo = ''
        state.form.pushCode = ''
        return
      }
      state.form[value].splice(index, 1)
      validIsEmpty()
    }

    // 文件上传为简历
    const file2Resume = (params: any) => {
      const loading = proxy.$loading
      loading.show('上传中...')
      proxy.$http.resume
        .upload(params)
        .then((res: any) => {
          loading.hide()
          handleFileParse()
          state.fileList = [
            {
              name: params.attachmentSourceName,
              url: params.attachmentUrl
            }
          ]
        })
        .catch(() => {
          state.fileList = []
          loading.hide()
        })
    }

    // 文件上传为作品
    const file2PersonalWork = (files: any, item: any) => {
      console.log(`file2PersonalWork`, files, item)
      item.fileList = [
        {
          name: files.attachmentSourceName,
          url: files.attachmentUrl,
          ...files
        }
      ]
    }

    // 简历解析
    const handleFileParse = () => {
      const loading = proxy.$loading
      loading.show('解析中...')
      setTimeout(() => {
        proxy.$http.resume
          .parse()
          .then((res: any) => {
            if (res === true) {
              ElMessage.success(`简历解析成功！`)
              getResumeInfo()
              loading.hide()
            } else {
              loading.hide()
            }
          })
          .catch((val) => {
            if (val.message.includes('当前登录手机号与简历附件中的手机号不一致')) {
              state.fileList = []
            } else {
              ElMessage.error(`简历解析超时！请重新点击[解析]按钮！`)
            }
            loading.hide()
          })
      }, 3000)
    }

    // 移除文件
    const handleFileRemove = (item: any) => {
      state.fileList = []
    }

    const handlePWFileRemove = (item: any) => {
      item.fileList = []
    }

    // 上传头像
    const handleAvatarUpload = (url: string, name: string | null = null) => {
      state.photoUrl = url
      state.empPhoto = name
    }

    // 社招校招切换
    const handleResumeTypeChange = () => {
      ElMessageBox.confirm('确定切换？', 'Warning', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        title: '提示'
      }).then(() => {
        if (state.resumeType == 1) {
          state.resumeType = 2
        } else if (state.resumeType == 2) {
          state.resumeType = 1
        }
        handleResumeTypeInit()
      })
    }

    // 选择社招校招
    const handleRecruitTypeChange = (value: number) => {
      state.resumeType = value
      state.recruitTypeVisible = false
      handleResumeTypeInit()
    }

    const handleResumeTypeInit = () => {
      if (state.resumeType == 1) {
        state.typeList = proxy.$Global.resumeTypeEnum.socialEnum
        delete state.resume.base.children[12].isRequire
      }
      if (state.resumeType == 2) {
        state.typeList = proxy.$Global.resumeTypeEnum.campusEnum
        state.resume.base.children[12].isRequire = false
      }
      state.tabList = [{ value: '基础信息' }, ...state.typeList, { value: '其他' }]

      validIsEmpty()
      nextTick(() => {
        proxy.$refs['formRef'] && proxy.$refs['formRef'].clearValidate()
      })
    }

    const handleSelectChange = (item: any, r: any, f: any) => {
      f[`${r.key}`] = item.dataCode
      f[`${r.value}`] = item.dataName
      if (r.value == 'educationTypeValue') {
        state.hasMaxEducationType = state.form.educationExperiences.some((el) => el.educationTypeKey === '30')
      }
    }

    const handleFamilySelectChange = (item: any, index: number, r: any) => {
      state.form.familyMemberVoList[index][r.key] = item.dataCode
      state.form.familyMemberVoList[index][r.value] = item.dataName
    }

    // 跳转到校验失败位置
    const scrollIntoView = (className = 'is-error') => {
      Promise.resolve().then(() => {
        const target = document.getElementsByClassName(className)
        if (target.length) {
          target[0].scrollIntoView({
            behavior: 'smooth', // 平滑过渡 值有auto、instant, smooth，缓动动画（当前是慢速的）
            block: 'center' // 上边框与视窗顶部平齐。默认值 值有start,center,end，nearest，当前显示在视图区域中间
          })
        }
      })
    }

    const scrollTo = (index?: number) => {
      let a: any = document.getElementById(`active${index}`)
      // document.body.scrollTo({ top: a.offsetTop - 45, behavior: 'smooth' })
      window.scrollTo({ top: a.offsetTop - 45, behavior: 'smooth' })
    }

    // 省市级(期望工作地)
    const handleProviceCity = (val: { ids: string[]; values: string[] }) => {
      state.form.expectWorkCity = val.values.join('/')
      state.form.expectWorkCityId = val.ids.join('>')
    }

    /**
     * 日期改变
     *
     * @description 年龄根据出生年月计算
     */
    const handleDateChange = (item: any, r: any, f: any) => {
      if (r.value == 'birthday' && state.form.birthday) {
        state.form.age = util.getAge(state.form.birthday.split('-'))
        resume.base.children.forEach((v) => {
          if (v.value == 'age') {
            v.disabled = true
          }
        })
      }
    }

    const addNewFamily = () => {
      let params = {}
      state.familyConfig.forEach((el) => {
        params[el.value] = ''
        if (el.key) {
          params[el.key] = ''
        }
      })
      state.form.familyMemberVoList.push(params)
    }

    const deleteFamily = (index) => {
      state.form.familyMemberVoList.splice(index, 1)
    }

    const handleRelativeRadio = (val) => {
      console.log('handleRelativeRadio', val, state.form.isTakeOffice)
      if (state.form.isTakeOffice && state.form.familyMemberVoList.length === 0) {
        // state.familyMemberVoList
        addNewFamily()
      }
    }
    const querySearchAsync = (queryString) => {
      state.queryString = queryString
      state.pageNum = 0
      state.positionOptions = []
      state.loading = true
      getPositionList()
    }
    const getPositionList = () => {
      if (state.queryString) {
        state.busy = true
        state.pageNum = state.pageNum + 1
        let data = {
          queryString: state.queryString,
          postType: 2,
          pageSize: 20,
          pageNum: state.pageNum
        }
        proxy.$http.position.getPostInfoPageList(data).then((res) => {
          state.busy = false
          state.loading = false

          state.positionOptions = state.positionOptions.concat(
            res.list.map((v) => {
              return {
                name: `${v.postNameOut}-${v.twoWorkPlaceName}`,
                ...v
              }
            })
          )
        })
      } else {
        state.positionOptions = []
      }
    }

    return {
      ...toRefs(state),
      validForm,
      submitForm,
      addRow,
      removeRow,
      file2Resume,
      file2PersonalWork,
      handleFileParse,
      handleFileRemove,
      handlePWFileRemove,
      handleAvatarUpload,
      handleResumeTypeChange,
      handleRecruitTypeChange,
      handleSelectChange,
      handleFamilySelectChange,
      scrollTo,
      handleProviceCity,
      handleDateChange,
      handleRelativeRadio,
      addNewFamily,
      deleteFamily,
      querySearchAsync,
      getPositionList,
      arabicToChineseNumber,
      addVolunteers,
      changeVolunteer,
      deleteVolunteer,
      changeVoluntSort
    }
  }
})
