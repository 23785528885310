
import { ref, defineComponent, getCurrentInstance, ComponentInternalInstance, reactive, toRefs } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { util } from '@/utils/util.js'
import cryptojs from '@/utils/sha1.js'
import type { ElForm } from 'element-plus'
import type { UploadFile } from 'element-plus/es/components/upload/src/upload.type'

// interface RawFile {
//   name: string
//   url: string
// }

interface stateOption {
  uploads: any
}

export default defineComponent({
  props: ['fileType', 'fileList'],
  setup(props, { emit }) {
    const { proxy }: { proxy: any } = getCurrentInstance() as ComponentInternalInstance
    const { fileList, fileType } = toRefs(props)
    const loading = proxy.$loading

    const state = reactive({
      uploads: {}
    }) as stateOption

    const handleExceed = (files: FileList, fileList: UploadFile[]) => {
      ElMessage.warning(`最大上传一个文件`)
    }

    const beforeUpload = (file: UploadFile) => {
      const extArr = [
        'DOCX',
        'DOC',
        'docx',
        'doc',
        'PDF',
        'pdf',
        'html',
        'HTML',
        'png',
        'jpg',
        'jpeg',
        'PNG',
        'JPG',
        'JPEG'
      ]
      // 判断文件上传类型
      if (fileType.value === 'resume' && !extArr.includes((file.name as any).split('.').pop())) {
        ElMessage.error('上传文件只能是pdf/docx/doc/html/png/jpg格式!')
        return false
      }

      // 判断文件上传大小
      if (file.size / 1024 / 1024 > 20) {
        ElMessage.error('上传文件大小不能超过 20MB!')
        return false
      }
    }

    // 手动上传文件获取Token
    const getUploadToken = (file: UploadFile) => {
      loading.show('上传中...')
      proxy.$http.resume
        .getToken()
        .then((res: any) => {
          let time = new Date().getTime()
          let nonce = cryptojs.sha1(time).toString()
          let timestamp = Number(time / 1000)

          state.uploads = {
            upload_token: res.uploadToken,
            file: file,
            group: 'private',
            appid: res.appId,
            nonce: nonce,
            signature: cryptojs.sha1([nonce, timestamp, res.uploadToken].sort().join('')).toString().toUpperCase(),
            timestamp: timestamp
          }
          uploadFile()
        })
        .catch(() => {
          emit('removeUpload')
          loading.hide()
        })
    }

    // 上传文件到文件服务器
    const uploadFile = () => {
      let formData = new FormData()
      formData.append('appid', state.uploads.appid)
      formData.append('upload_token', state.uploads.upload_token)
      formData.append('group', state.uploads.group)
      formData.append('timestamp', state.uploads.timestamp)
      formData.append('nonce', state.uploads.nonce)
      formData.append('filename', util.guid())
      formData.append('signature', state.uploads.signature)
      formData.append('ext', state.uploads.file.file.name.substring(state.uploads.file.file.name.lastIndexOf('.') + 1))
      formData.append('uploadfile', state.uploads.file.file)
      proxy.$http.resume
        .uploadEncryptFile(formData)
        .then((res: any) => {
          let params = {
            attachmentName: res.fileName,
            attachmentUrl: res.url,
            attachmentSourceName: state.uploads.file.file.name,
            recruitingSourceKey: '31'
          }
          loading.hide()
          emit('successUpload', params)
        })
        .catch(() => {
          loading.hide()
          emit('removeUpload')
        })
    }

    // 解析简历
    const handleFileParse = () => {
      emit('fileParse')
    }

    // 删除简历
    const handleFileRemove = () => {
      emit('removeUpload')
    }

    return {
      ...toRefs(state),
      handleExceed,
      beforeUpload,
      getUploadToken,
      handleFileRemove,
      handleFileParse
    }
  }
})
