import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_cascader = _resolveComponent("el-cascader")!

  return (_openBlock(), _createBlock(_component_el_cascader, {
    modelValue: _ctx.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
    ref: "regionRef",
    options: _ctx.options,
    props: { label: 'name', value: 'code', emitPath: true },
    clearable: "",
    onChange: _ctx.handleRegionChange
  }, null, 8, ["modelValue", "options", "onChange"]))
}