
import {
  defineComponent,
  reactive,
  toRefs,
  getCurrentInstance,
  ComponentInternalInstance,
  onMounted,
  ref,
  nextTick
  // onActivated
} from 'vue'
import { useRouter, useRoute, onBeforeRouteLeave } from 'vue-router'
import { useStore } from 'vuex'
import { Edit, ArrowRight, CirclePlus, Remove } from '@element-plus/icons'
import cryptojs from '@/utils/sha1.js'
import { util } from '@/utils/util.js'
import { resume } from '@/views/my-management/config'
import { ZMessage, ZConfirm } from '@/utils/confirm'
import { campusHostName, Env } from '@/config/env'
export default defineComponent({
  components: { Edit, ArrowRight, CirclePlus, Remove },
  setup() {
    const { proxy }: { proxy: any } = getCurrentInstance() as ComponentInternalInstance
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const loading = proxy.$loading
    const pushFormRef = ref(null)
    const iframeRef = ref(null)
    const wrapRef = ref(null)
    const headRef = ref(null)
    const state: any = reactive({
      token: '',
      iframeSrc: `${campusHostName[Env]}/#/baseInformation`,
      timeStamp: '',
      iframeHeight: 0,
      tokenPostId: '',
      options: [
        { text: '社会招聘', value: 1 },
        { text: '校园招聘', value: 2 },
        { text: '一线招聘', value: 3 }
      ],
      resumeTitles: ['社会招聘', '校园招聘', '一线招聘'],
      isFreeLogin: false,
      encryptStr: null,
      postId: null,
      resumeType: 1, // 1-社招 2-校招 3-一线
      submitFormType: 0,
      recruitTypeVisible: false,
      form: {
        id: null,
        photoUrl: null,
        // 教育经历
        educationExperiences: [],
        // 工作经历(社招)
        workExperiences: [],
        // 项目经历
        projectExperiences: [],
        // 实习经历(校招)
        practiceExperienceVoList: [],
        // 在校职务(校招)
        schoolOfficeVoList: [],
        // 作品 worksType: 1-作品附件 2-作品链接
        personalWorksList: [],
        // 获奖
        awardsWonsVoList: [],
        // 语言
        languageAbilityVoList: [],
        // 自我评价
        selfEvaluation: null,
        educationExperiencesIsEmpty: '1',
        workIsEmpty: '1',
        proIsEmpty: '1',
        pushCode: null,
        recommendedType: null
      },
      fileList: [],
      typeList: proxy.$Global.resumeTypeEnum.socialEnum,
      otherTypeList: proxy.$Global.resumeTypeEnum.othersEnum,
      position: {
        postNameOut: null,
        deptName: null,
        workPlaceName: null
      },
      resume,
      baseIsComplate: false,
      evalIsComplate: false,
      pushAreaDisplay: false
    })

    onMounted(() => {
      console.log(`onMounted`, store.state.resumeInfo, state.iframeHostName, Env)
      if (!location.href.includes('encryptStr=')) {
        if (!localStorage.getItem('Authorization')) {
          store.dispatch('setRedirectUrl', route.fullPath)
          router.replace({
            name: 'Login'
          })
          return false
        }
        if (!store.state.resumeInfo) {
          // 处理微信登录重定向: url带code和state不挂载
          if (!(location.href.includes('code=') && location.href.includes('state='))) {
            state.postId = route.query.postId
            judgeIshasPostId()
          }
        } else {
          console.log('现在的state', store.state.resumeInfo.resumeType)
          state.form = store.state.resumeInfo
          if (store.state.resumeInfo.postId) {
            state.postId = store.state.resumeInfo.postId
            state.position = store.state.resumeInfo.position
          }
          if (state.form.recommendedType) {
            state.pushAreaDisplay = true
          }
          state.resumeType = store.state.resumeInfo.resumeType
          handleResumeTypeInit()
        }
      } else {
        state.isFreeLogin = true
        store.dispatch('setIsFreeLogin', state.isFreeLogin)
        state.encryptStr = location.href.split('encryptStr=')[1]
        if (!store.state.resumeInfo) {
          getResumeInfoFree()
        } else {
          console.log(`store.state.resumeInfo`, store.state.resumeInfo)
          state.form = store.state.resumeInfo
          if (store.state.resumeInfo.postId) {
            state.postId = store.state.resumeInfo.postId
            state.position = store.state.resumeInfo.position
          }
          state.resumeType = store.state.resumeInfo.resumeType
          handleResumeTypeInit()
        }
      }
      console.log('route', route, route.query)
      // 一线简历投递处理
      if (route.params.resumeType) {
        state.resumeType = Number(route.params.resumeType)
        state.form.resumeType = state.resumeType
        route.params.resumeType = null
        // 保存到store
        store.dispatch('setResumeType', state.resumeType)
        console.log('route', state.resumeType)
      }
      if (route.params.postId) {
        state.tokenPostId = route.params.postId
        route.params.postId = null
      }
      // 首先进入store保存的resumeType
      if (store.state.resumeType) {
        state.resumeType = Number(store.state.resumeType)
        state.form.resumeType = state.resumeType
      } else {
        state.resumeType = 1
        state.form.resumeType = 1
      }
      state.token = localStorage.getItem('Authorization')
      // 传递postId是复杂模板
      state.iframeSrc = `${state.iframeSrc}?token=${state.token}&postId=${state.tokenPostId}`
      if (state.resumeType === 3) {
        console.log('进来了吗', state.resumeType)
        nextTick(() => {
          getIframeHeight()
          // console.log('iframeTop', iframe.offsetTop)
        })
      }
    })

    const getIframeHeight = () => {
      // 取网页高度，取手机高度会有问题
      const screenHeight = document.documentElement.clientHeight
      const headerHeight = headRef.value.offsetHeight
      state.iframeHeight = screenHeight - headerHeight
      console.log('height', screenHeight, headerHeight)
    }

    /**
     * @description : 判断是[职位投递页]还是[我的简历]
     * @result : postId： 有-判断投递状态 无：获取简历信息
     */
    const judgeIshasPostId = () => {
      if (state.postId) {
        judgeResumeStatu()
      } else {
        getResumeInfo()
      }
    }

    /**
     * @description : [职位投递页]校验简历投递状态
     * @result : 可以投递-获取岗位信息获取简历信息
     * @result : 不可投递-超过职位投递个数的最大限制2-跳转回前一页
     * @result : 不可投递-已投递此岗位，请勿重复投递！跳转回前一页
     * @result : 不可投递-未登录-跳转到登录页
     */
    const judgeResumeStatu = () => {
      proxy.$http.resume
        .checkResumeStatu({ postId: state.postId, recruitingSourceKey: '31' })
        .then(() => {
          getPositionDetail()
        })
        .catch((err: any) => {
          if (err.statusCode == '403') {
            store.dispatch('setRedirectUrl', route.fullPath)
            router.replace({
              name: 'Login'
            })
          } else {
            router.go(-1)
          }
        })
    }

    /**
     * @description : 获取岗位详情
     * @result : 职位不在发布中-返回
     * @result : 职位在发布中-获取招聘类型
     */
    const getPositionDetail = () => {
      proxy.$http.position.getPostInfoDetail({ id: state.postId }).then((res: any) => {
        if (res.status == 2) {
          state.position = res
          if (res.channelId == 3) {
            state.resumeType = 1
          } else {
            state.resumeType = res.channelId || null
          }
          getResumeInfo()
        } else {
          router.go(-1)
        }
      })
    }

    // onActivated(() => {
    //   store.state.resumeInfo && (state.form = store.state.resumeInfo)
    // })

    /**
     * @description : 获取简历信息！！！
     * @description : 注意异常处理
     */
    const getResumeInfo = () => {
      proxy.$http.resume.getResumeInfo().then((res: any) => {
        state.form = res
        console.log('state', state)
        if (state.form.birthday) {
          state.form.age = util.getAge(state.form.birthday.split('-'))
        }
        if (state.form.recommendedType) {
          state.pushAreaDisplay = true
        }
        if (!state.postId) {
          if (!state.form.id && !route.query.resumeType) {
            state.recruitTypeVisible = true
          } else {
            // 招聘类型：没有招聘类型时才改 默认取社招
            if (state.resumeType === null) {
              state.resumeType = res.resumeType
            }
            state.recruitTypeVisible = state.resumeType === null
            // 处理社招校招展示
            handleResumeTypeInit()
          }
        } else {
          handleResumeTypeInit()
        }

        for (const k of Object.keys(resume)) {
          if (k != 'base') {
            if (!state.form[k]) {
              state.form[k] = []
            }
          }
        }

        // 简历投递存职位信息
        if (state.postId) {
          state.form.postId = state.postId
          state.form.position = state.position
        }
        // 如果不存在, 弹出选择框
        if (!state.form.resumeType) {
          state.recruitTypeVisible = true
        } else {
          state.form.resumeType = state.resumeType
        }
        // 如果是正常的，亲属回避不必填
        //测试用
        store.dispatch('setResumeInfo', state.form)
        // 处理简历解析后期望工作地回显问题
        if (state.form.expectWorkCityId && !state.form.expectWorkCity) {
          proxy.$http.base.getRegionInfoTreeList().then((res) => {
            console.log('getRegionInfoTreeList', res)
            const data = state.form.expectWorkCityId.split('>')
            let expectWorkCity = []
            const first = res.children.find((v) => {
              return v.code === data[0]
            })
            if (first) {
              expectWorkCity.push(first.name)
              const result = first.children.find((v) => {
                return v.code === data[1]
              })
              if (result) {
                expectWorkCity.push(result.name)
                state.form.expectWorkCity = expectWorkCity.join('/')
              }
            }
          })
        }
      })
    }

    /**
     * 处理未登录简历
     *
     */
    const getResumeInfoFree = () => {
      proxy.$http.blue.getResumeInfo({ encryptStr: state.encryptStr }).then((res: any) => {
        state.form = res.baseResumeInfo
        state.id = state.form.id
        state.photoUrl = state.form.photoUrl
        state.recruitTypeVisible = false
        state.resumeType = res.type
        state.postId = res.postId
        state.position = {
          postNameOut: res.postName,
          deptNameStr: res.deptName,
          requireAgeName: res.requireAgeName,
          workPlaceNameStr: res.workPlaceName
        }
        if (state.form.birthday) {
          state.form.age = util.getAge(state.form.birthday.split('-'))
        }
        handleResumeTypeInit()
        state.form.age = state.form.birthday && util.getAge(state.form.birthday.split('-'))
        for (const k of Object.keys(resume)) {
          if (k != 'base') {
            if (!state.form[k]) {
              state.form[k] = []
            }
          }
        }
        // 简历投递存职位信息
        if (state.postId) {
          state.form.postId = state.postId
          state.form.position = state.position
        }
        state.form.resumeType = state.resumeType
        store.dispatch('setResumeInfo', state.form)
      })
    }

    const handlePushChange = (v) => {
      nextTick(() => {
        document.documentElement.scrollTop = 1000
      })
    }

    const handleItemClick = (path: string, index?: number) => {
      if (path === 'pushAdd') {
        state.pushAreaDisplay = true
        state.form.recommendedType = 1
        return
      }
      if (path === 'pushRemove') {
        state.pushAreaDisplay = false
        state.form.recommendedType = ''
        state.form.recommendedName = ''
        state.form.recommendedInfo = ''
        state.form.pushCode = ''
        return
      }
      router.push({
        name: 'myResumeEdit',
        query: {
          path,
          index: index !== null ? index : null
        }
      })
    }

    const beforeRead = (file) => {
      return true
    }
    const afterRead = (file, isAvatar) => {
      loading.show('上传中...')
      proxy.$http.resume
        .getToken()
        .then((res: any) => {
          let time = new Date().getTime()
          let nonce = cryptojs.sha1(time).toString()
          let timestamp = Number(time / 1000)

          let uploads = {
            upload_token: res.uploadToken,
            file: file,
            group: 'private',
            appid: res.appId,
            nonce: nonce,
            signature: cryptojs.sha1([nonce, timestamp, res.uploadToken].sort().join('')).toString().toUpperCase(),
            timestamp: timestamp
          }
          uploadFile(uploads, isAvatar)
        })
        .catch(() => {
          state.fileList = []
          loading.hide()
        })
    }

    // 上传文件到文件服务器
    const uploadFile = (uploads, isAvatar) => {
      let formData = new FormData()
      formData.append('appid', uploads.appid)
      formData.append('upload_token', uploads.upload_token)
      formData.append('group', uploads.group)
      formData.append('timestamp', uploads.timestamp)
      formData.append('nonce', uploads.nonce)
      formData.append('filename', util.guid())
      formData.append('signature', uploads.signature)
      formData.append('ext', uploads.file.file.name.substring(uploads.file.file.name.lastIndexOf('.') + 1))
      formData.append('uploadfile', uploads.file.file)

      if (isAvatar) {
        proxy.$http.resume
          .uploadFile(formData)
          .then((res: any) => {
            state.form.photoUrl = res.url
            store.dispatch('setResumeInfo', state.form)
          })
          .catch(() => {
            state.form.photoUrl = null
            store.dispatch('setResumeInfo', state.form)
          })
          .finally(() => {
            loading.hide()
          })
      } else {
        proxy.$http.resume
          .uploadEncryptFile(formData)
          .then((res: any) => {
            let params = {
              attachmentName: res.fileName,
              attachmentUrl: res.url,
              attachmentSourceName: uploads.file.file.name,
              recruitingSourceKey: '31'
            }
            file2Resume(params)
          })
          .catch(() => {
            state.fileList = []
            loading.hide()
          })
      }
    }

    const file2Resume = (params) => {
      proxy.$http.resume
        .upload(params)
        .then((res: any) => {
          // 20220505 应产品要求改为：上传完马上解析
          loading.hide()
          handleFileParse()
          state.fileList = [
            {
              name: params.attachmentSourceName,
              url: params.attachmentUrl
            }
          ]
        })
        .catch(() => {
          loading.hide()
          state.fileList = []
        })
    }

    const handleFileParse = () => {
      // ZConfirm('解析成功会覆盖已填内容，是否继续？', 'Warning').then(() => {})
      loading.show('解析中...')
      setTimeout(() => {
        proxy.$http.resume
          .parse()
          .then((res: any) => {
            if (res === true) {
              ZMessage({
                message: `简历解析成功！`,
                type: 'success'
              })
              if (state.resumeType === 3) {
                nextTick(() => {
                  state.timeStamp = Date.now()
                  console.log('进入了', state.timeStamp)
                })
              } else {
                getResumeInfo()
              }
              loading.hide()
            } else {
              loading.hide()
              ZMessage({
                message: `简历解析超时！请重新点击[解析]按钮！`,
                type: 'error'
              })
            }
          })
          .catch(() => {
            loading.hide()
          })
      }, 3000)
    }

    // 移除文件
    const handleFileRemove = () => {
      ZConfirm('确定移除附件吗？', 'Warning').then(() => {
        state.fileList = []
      })
    }

    // 移除头像
    const handleAvatarRemove = () => {
      state.form.photoUrl = null
    }

    // 判断简历填写完整
    const judgeFormComplate = () => {
      try {
        state.resume.base.children.forEach((v: any) => {
          if (v.isRequire !== false && (state.form[v.value] === null || state.form[v.value] === '')) {
            console.log('报错的', v)
            state.baseIsComplate = false
            throw new Error('基本信息没有填写完整！')
          } else {
            state.baseIsComplate = true
          }
        })
      } catch (error) {
        console.warn(error)
      }

      if (state.form.selfEvaluation === null || state.form.selfEvaluation === '') {
        state.evalIsComplate = false
      } else {
        state.evalIsComplate = true
      }

      let requiredTypeList = ['educationExperiences']
      if (state.resumeType == 1) {
        requiredTypeList.push('workExperiences')
      }

      let lists = [...state.typeList]

      try {
        lists.forEach((type: any) => {
          if (requiredTypeList.includes(type.key)) {
            if (state.form[type.key].length !== 0) {
              state.form[type.key].forEach((item: any, index: number) => {
                resume[type.key].children.forEach((v: any) => {
                  if (v.isRequire !== false && (item[v.value] === null || item[v.value] === '')) {
                    type.isComplate = false
                    throw new Error(`${type.key}没有填写完整！`)
                  } else {
                    type.isComplate = true
                  }
                })
              })
            } else {
              type.isComplate = false
            }
          }
        })
      } catch (error) {
        console.warn(error)
      }
    }

    // 校验简历填写完整
    const handleFormValid = (): boolean => {
      let formIsComplate = true
      try {
        console.log(proxy)
        state.resume.base.children.forEach((v: any) => {
          if (v.isRequire !== false && (state.form[v.value] === null || state.form[v.value] === '')) {
            console.log(`v`, v, state.form[v.value])
            ZMessage({
              message: `基本信息没有填写完整！`,
              type: 'error'
            })
            formIsComplate = false
            throw new Error(`基本信息没有填写完整！`)
          }
        })

        if (state.form.selfEvaluation === null || state.form.selfEvaluation === '') {
          ZMessage({
            message: `自我评价没有填写完整！`,
            type: 'error'
          })
          formIsComplate = false
          throw new Error(`自我评价没有填写完整！`)
        }

        let requiredTypeList = [
          {
            key: 'educationExperiences',
            value: '教育经历'
          }
        ]
        if (state.resumeType == 1) {
          requiredTypeList.push({
            key: 'workExperiences',
            value: '工作经历'
          })
        }

        requiredTypeList.forEach((type: any) => {
          if (state.form[type.key].length === 0) {
            ZMessage({
              message: `${type.value}没有填！`,
              type: 'error'
            })
            formIsComplate = false
            throw new Error(`${type.value}没有填！`)
          }
        })

        let lists = [...state.typeList, ...state.otherTypeList]
        lists.forEach((type: any) => {
          if (state.form[type.key].length !== 0) {
            state.form[type.key].forEach((item: any, index: number) => {
              resume[type.key].children.forEach((v: any) => {
                if (
                  v.isRequire !== false &&
                  ((item.worksType == 1 && !item.attachmentSourceName) ||
                    (item.worksType != 1 && (item[v.value] === '' || item[v.value] === null)))
                ) {
                  ZMessage({
                    message: `第${index + 1}条${type.value}没有填写完整！`,
                    type: 'error'
                  })
                  formIsComplate = false
                  throw new Error(`第${index + 1}条${type.value}没有填写完整！`)
                }
              })
            })
          }
        })
        if (
          (state.form.recommendedType === 1 && !state.form.pushCode) ||
          (state.form.recommendedType === 2 && !(state.form.recommendedName && state.form.recommendedInfo))
        ) {
          pushFormRef.value
            .validate()
            .then()
            .catch((err) => {
              console.log(err)
              pushFormRef.value.scrollToField(err[0].name)
            })
          ZMessage({
            message: `内推信息没有填写完整！`,
            type: 'error'
          })
          formIsComplate = false
          throw new Error(`内推信息没有填写完整！`)
        }
      } catch (e) {
        return
      }
      return formIsComplate
    }

    // 保存简历
    const handleSubmit = (submitFormType: number) => {
      let formIsComplate = handleFormValid()
      if (!formIsComplate) return false
      ZConfirm(`${submitFormType == 1 ? '最多能投递2个岗位，确定投递' : '确定保存'}？`, 'Warning').then(() => {
        if (!state.form.recommendedType || state.form.recommendedType === 1) {
          state.form.recommendedName = ''
          state.form.recommendedInfo = ''
        }
        if (!state.form.recommendedType || state.form.recommendedType === 2) {
          state.form.pushCode = ''
        }
        const params = {
          ...state.form,
          id: state.form.id,
          resumeType: state.resumeType,
          photoUrl: state.form.empPhoto ? null : state.form.photoUrl,
          empPhoto: state.form.empPhoto,
          realName: state.form.realName,
          sexKey: state.form.sexKey,
          phone: state.form.phone,
          email: state.form.email,
          birthday: state.form.birthday,
          marryKey: state.form.marryKey,
          marryValue: state.form.marryValue,
          workYears: state.form.workYears,
          workYearsValue: state.form.workYearsValue,
          nativePlaceKey: state.form.nativePlaceKey,
          nativePlaceValue: state.form.nativePlaceValue,
          currentResidence: state.form.currentResidence,
          expectWorkCity: state.form.expectWorkCity,
          expectWorkCityId: state.form.expectWorkCityId,
          expectSalary: state.form.expectSalary,
          currSalary: state.form.currSalary,
          selfEvaluation: state.form.selfEvaluation,
          educationExperiences: state.form.educationExperiences,
          projectExperiences: state.form.projectExperiences,
          workExperiences: state.form.workExperiences,
          practiceExperiences: state.form.practiceExperienceVoList,
          schoolOfficeVoList: state.form.schoolOfficeVoList,
          personalWorksList: state.form.personalWorksList,
          awardsWonsList: state.form.awardsWonsVoList,
          languageAbilityList: state.form.languageAbilityVoList,
          pushCode: state.form.pushCode,
          encryptStr: state.encryptStr,
          recommendedName: state.form.recommendedName,
          recommendedInfo: state.form.recommendedInfo,
          recommendedType: state.form.recommendedType,
          familyMemberList:
            state.form.isTakeOffice === '1' || state.form.isTakeOffice === 1 ? state.form.familyMemberVoList : [],
          bankCardPo: state.form.bankCardVo // 一线的银行卡保存
        }
        console.log('state.form', state.form, params)
        if (state.isFreeLogin == false) {
          proxy.$http.resume.updateResumeInfo(params).then((res: any) => {
            if (submitFormType == 1) {
              sendResume()
            } else {
              ZMessage({
                message: '保存成功！',
                type: 'success'
              })
              handleRouteBack()
            }
          })
        } else {
          proxy.$http.blue.updateResumeInfo(params).then((res: any) => {
            ZMessage({
              message: '更新成功！',
              type: 'success'
            })
            getResumeInfoFree()
          })
        }
      })
    }

    // 投递简历
    const sendResume = () => {
      proxy.$http.position
        .sendResume({
          postId: state.postId,
          recruitingSourceKey: '微官网',
          pushCode: state.form.pushCode,
          postOrderPoList: []
        })
        .then((res: any) => {
          ZMessage({
            message: '投递成功！',
            type: 'success'
          })
          router.push({
            name: 'PositionDetail',
            query: {
              id: state.postId
            }
          })
        })
    }

    // 选择社招校招
    const handleRecruitTypeChange = (value: number) => {
      state.resumeType = value
      state.recruitTypeVisible = false
      state.form.resumeType = state.resumeType
      store.dispatch('setResumeInfo', state.form)
      store.dispatch('setResumeType', state.resumeType)
      nextTick(() => {
        getIframeHeight()
      })
      handleResumeTypeInit()
    }

    // 社招校招切换
    const handleResumeTypeChange = (value) => {
      console.log('变化了', value, state.resumeType, typeof value)
      ZConfirm('确定切换？', 'Warning').then(() => {
        // if (state.resumeType == 1) {
        //   state.resumeType = 2
        // } else if (state.resumeType == 2) {
        //   state.resumeType = 1
        // }
        // state.resumeTypeTitle = state.options.find((v) => v.value === value).text
        state.resumeType = value
        state.form.resumeType = state.resumeType
        store.dispatch('setResumeInfo', state.form)
        store.dispatch('setResumeType', state.resumeType)
        if (state.form.resumeType === 3) {
          nextTick(() => {
            getIframeHeight()
          })
        }
        handleResumeTypeInit()
      })
    }

    const handleResumeTypeInit = () => {
      console.log('fdsf', state.resumeType)
      if (state.resumeType == 1) {
        state.typeList = proxy.$Global.resumeTypeEnum.socialEnum
        delete state.resume.base.children[12].isRequire
      }
      if (state.resumeType == 2) {
        state.typeList = proxy.$Global.resumeTypeEnum.campusEnum
        state.resume.base.children[12].isRequire = false
      }
      console.log('resume', state.resume)
      // 只有是免登陆而且是社招，亲属回避才必填
      if (!(state.resumeType === 1 && state.encryptStr)) {
        state.resume.base.children.forEach((el) => {
          if (el.value === 'isTakeOffice') {
            el.isRequire = false
          }
        })
      }
      judgeFormComplate()
    }

    const handleRouteBack = () => {
      store.dispatch('setResumeInfo', null)
      if (state.postId) {
        router.push({
          name: 'PositionDetail',
          query: {
            id: state.postId
          }
        })
      } else {
        router.push({
          name: 'my'
        })
      }
    }

    // 离开前清除ResumeInfo
    onBeforeRouteLeave((to) => {
      if (to.name != 'myResumeEdit') {
        store.dispatch('setResumeInfo', null)
      }
      // 离开前保存resumeType
      console.log('resumeType', state.resumeType)
      store.dispatch('setResumeType', state.resumeType)
    })

    function handleLoginOut() {
      localStorage.removeItem('Authorization')
      store.dispatch('setUserInfo', {})
      router.push({
        path: '/'
      })
    }

    return {
      ...toRefs(state),
      afterRead,
      beforeRead,
      handleItemClick,
      handleFileParse,
      handleFileRemove,
      handleAvatarRemove,
      handleSubmit,
      handleRecruitTypeChange,
      handleResumeTypeChange,
      handleFormValid,
      handleRouteBack,
      pushFormRef,
      handlePushChange,
      wrapRef,
      headRef,
      iframeRef,
      getIframeHeight
    }
  }
})
