
import { defineComponent, getCurrentInstance, onMounted, reactive, toRefs, ref, watch, nextTick } from 'vue'

export default defineComponent({
  props: {
    num: {
      type: Number,
      required: false
    },
    valueIds: {
      type: String
    },
    moduleValue: Object
  },
  emits: ['callback', 'update:module-value'],
  setup(props, { emit }) {
    const { proxy }: { proxy: any; refs: any } = getCurrentInstance()
    const state = reactive({
      regionTree: [],
      options: [],
      value: []
    })
    const { num, valueIds } = reactive(props)

    onMounted(() => {
      // console.log(regionRef)
      // state.value = valueIds.split('>')
      console.log('valids', state, valueIds, num)
      proxy.$http.base.getRegionInfoTreeList().then((res) => {
        state.regionTree = res.children
        if (!num) {
          state.options = state.regionTree
        } else {
          state.options = handleNumber(state.regionTree, num)
        }
      })
    })

    const handleNumber = (arr: any[], num): any[] => {
      let newArr: any[] = arr
      let n = num - 1
      newArr.forEach((el) => {
        if (n === 0) {
          el.children = null
        } else if (el.children) {
          handleNumber(el.children, n) //递归调用 传入子数组
        }
      })
      return newArr
    }

    const regionRef = ref(null)
    const handleRegionChange = () => {
      let data: { ids: string[]; values: string[] } = {
        ids: [],
        values: []
      }
      const node = regionRef.value.getCheckedNodes()
      console.log('node', node, valueIds)
      if (node[0] && node[0].pathLabels && node[0].pathValues) {
        data.ids = node[0].pathValues
        data.values = node[0].pathLabels
      }
      // console.log(state.value)
      emit('callback', data)
    }

    watch([props], (newVal, oldVal): void => {
      console.log('newValue', newVal, oldVal, newVal[0].valueIds)
      if (newVal && newVal[0].valueIds) {
        const expectWorkCityId = newVal[0].valueIds + ''
        state.value = expectWorkCityId.split('>')
      }
    })
    return {
      ...toRefs(state),
      handleRegionChange,
      regionRef
    }
  }
})
