/*
 * @Author: chengweijia-tel:18702108979
 * @Version: 1.0
 * @Date: 2022-02-17 09:54:13
 * @LastEditTime: 2024-03-28 16:13:34
 */
export const resume = {
  base: {
    key: 'base',
    value: '基本信息',
    children: [
      { label: '姓名', value: 'realName' },
      { label: '性别', value: 'sexKey', formType: 'radio' },
      { label: '电话', value: 'phone', disabled: true },
      { label: '邮箱', value: 'email' },
      { label: '出生日期', value: 'birthday', formType: 'datePicker' },
      { label: '年龄', value: 'age', disabled: false },
      {
        label: '婚姻状况',
        value: 'marryValue',
        key: 'marryKey',
        formType: 'select',
        isRequire: false,
        selectKey: 'MAR_STATUS',
        selectEnum: []
      },
      {
        label: '工作年限',
        value: 'workYearsValue',
        key: 'workYears',
        formType: 'select',
        selectKey: 'year_limit',
        selectEnum: []
      },
      { label: '现居住地', value: 'currentResidence' },
      {
        label: '籍贯',
        value: 'nativePlaceValue',
        key: 'nativePlaceKey',
        formType: 'select',
        isRequire: false,
        selectKey: 'C_NID_STATE',
        selectEnum: []
      },
      { label: '期望工作地', value: 'expectWorkCity', formType: 'provinceCity' },
      { label: '期望薪资', value: 'expectSalary' },
      { label: '目前薪资', value: 'currSalary', isRequire: false },
      {
        label: '亲属任职中通',
        value: 'isTakeOffice',
        formType: 'radio'
      }
    ]
  },
  educationExperiences: {
    key: 'educationExperiences',
    value: '教育经历',
    children: [
      { label: '起止时间', value: 'dateRange', formType: 'datePickerRange' },
      { label: '学校名称', value: 'schoolName' },
      { label: '专业名称', value: 'profession' },
      {
        label: '学历',
        value: 'educationValue',
        key: 'educationKey',
        formType: 'select',
        selectKey: 'C_EDU_XUELI',
        selectEnum: []
      },
      {
        label: '学位',
        value: 'topDegreeValue',
        key: 'topDegreeKey',
        formType: 'select',
        selectKey: 'C_EDU_XUEWEI',
        selectEnum: []
      },
      {
        label: '学习形式',
        value: 'schoolType',
        key: 'schoolTypeKey',
        formType: 'select',
        selectKey: 'C_EDU_TYPE',
        selectEnum: []
      },
      {
        label: '学历类型',
        value: 'educationTypeValue',
        key: 'educationTypeKey',
        formType: 'select',
        selectKey: 'C_EDU_XL_FLAG',
        selectEnum: [],
        disabled: false
      }
    ]
  },
  workExperiences: {
    key: 'workExperiences',
    value: '工作经历',
    children: [
      { label: '起止时间', value: 'dateRange', formType: 'datePickerRange' },
      { label: '公司', value: 'companyName' },
      { label: '部门', value: 'departmentName', isRequire: false },
      { label: '职位', value: 'postName' },
      { label: '职责描述', value: 'workDesc', formType: 'textarea' }
    ]
  },
  projectExperiences: {
    key: 'projectExperiences',
    value: '项目经验',
    children: [
      { label: '起止时间', value: 'dateRange', formType: 'datePickerRange' },
      { label: '项目名称', value: 'projectName' },
      { label: '项目描述', value: 'projectDesc', formType: 'textarea' },
      { label: '项目职责', value: 'projectDuty', formType: 'textarea' }
    ]
  },
  practiceExperienceVoList: {
    key: 'practiceExperienceVoList',
    value: '实习经历',
    children: [
      { label: '起止时间', value: 'dateRange', formType: 'datePickerRange' },
      { label: '公司', value: 'practiceCompanyName' },
      { label: '职位', value: 'practiceReferencesPosition' },
      { label: '职责描述', value: 'practiceContent', formType: 'textarea' }
    ]
  },
  schoolOfficeVoList: {
    key: 'schoolOfficeVoList',
    value: '在校职务',
    children: [
      { label: '起止时间', value: 'dateRange', formType: 'datePickerRange' },
      { label: '职务名称', value: 'cadreDescription' },
      { label: '职务描述', value: 'cadreName', formType: 'textarea' }
    ]
  },
  // worksType1 附件 attachmentName attachmentSourceName attachmentUrl
  // worksType2 链接 worksUrl
  personalWorksList: {
    key: 'personalWorksList',
    value: '作品',
    children: [
      { label: '作品类型', value: 'worksType', formType: 'radio' },
      { label: '作品链接', value: 'worksUrl' },
      { label: '作品附件', value: 'fileList', formType: 'upload' },
      { label: '描述', value: 'worksDescription', formType: 'textarea', isRequire: false }
    ]
  },
  awardsWonsVoList: {
    key: 'awardsWonsVoList',
    value: '获奖经历',
    children: [
      { label: '获奖名称', value: 'awardName' },
      { label: '获奖时间', value: 'awardTime', formType: 'datePicker', isRequire: false },
      { label: '描述', value: 'awardDescription', formType: 'textarea' }
    ]
  },
  languageAbilityVoList: {
    key: 'languageAbilityVoList',
    value: '语言',
    children: [
      {
        label: '语言种类',
        value: 'languageTypeValue',
        key: 'languageTypeKey',
        formType: 'select',
        selectKey: 'C_LANGUAGE',
        selectEnum: []
      },
      { label: '获证日期', value: 'certificateDate', formType: 'datePicker' },
      {
        label: '熟练程度',
        value: 'proficiencyValue',
        key: 'proficiencyKey',
        formType: 'select',
        selectKey: 'C_HR_LANG_DEGREE',
        selectEnum: []
      },
      {
        label: '认证等级',
        value: 'certificationLevelValue',
        key: 'certificationLevelKey',
        formType: 'select',
        selectKey: 'C_HR_LANG_LEVEL',
        selectEnum: []
      },
      {
        label: '证书名称',
        value: 'certificateName'
      },
      {
        label: '证书编号',
        value: 'certificateNum',
        isRequire: false
      }
    ]
  },
  blueInfo: [
    { label: '姓名', value: 'realName' },
    { label: '证件号码', value: 'cardNo' },
    { label: '证件到期日期', value: 'certificateDueDate', formType: 'datePicker' },
    { label: '出生日期', value: 'birthday', formType: 'datePicker' },
    {
      label: '性别',
      value: 'sexValue',
      key: 'sexKey',
      formType: 'select',
      selectKey: 'sex',
      selectEnum: [
        {
          dataName: '男',
          dataCode: '0'
        },
        {
          dataName: '女',
          dataCode: '1'
        }
      ]
    },
    {
      label: '籍贯省份',
      value: 'nativePlaceValue',
      key: 'nativePlaceKey',
      formType: 'select',
      selectKey: 'C_NID_STATE',
      selectEnum: []
    },
    {
      label: '身份证所属省份',
      value: 'provinceOfIdCard',
      key: 'provinceOfIdCardKey',
      formType: 'select',
      selectKey: 'C_NID_STATE',
      selectEnum: []
    },
    { label: '身份证详细地址', value: 'idCardAddress', formType: 'textarea' }
  ]
}
